/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
Vue.use(require('vue-moment'));
window.events = new Vue();

window.flash = function(message) {
   window.events.$emit('flash',message);
}
window.errorflash = function(message) {
   window.events.$emit('errorflash',message);
}

Vue.component('flash', require('./components/Flash.vue').default);
Vue.component('errorflash', require('./components/Errorflash.vue').default);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.component('admin-list', require('./components/dashboard/AdminList.vue').default);
Vue.component('users-list', require('./components/dashboard/UsersList.vue').default);


Vue.component('about-us-list', require('./components/dashboard/AboutusList.vue').default);
Vue.component('services-list', require('./components/dashboard/ServicesList.vue').default);
Vue.component('subscriptions-list', require('./components/dashboard/SubscriptionsList.vue').default);

Vue.component('section-services', require('./components/dashboard/ServicesSection.vue').default);
Vue.component('section-blog', require('./components/dashboard/BlogSection.vue').default);
Vue.component('images', require('./components/dashboard/ServicesImage.vue').default);

Vue.component('blogs-list', require('./components/dashboard/BlogsList.vue').default);
Vue.component('projects-list', require('./components/dashboard/ProjectsList.vue').default);


Vue.component('clients-list', require('./components/dashboard/ClientsList.vue').default);
Vue.component('contact-us', require('./components/dashboard/ContactUs.vue').default);
Vue.component('visitors-list', require('./components/dashboard/VisitorsList.vue').default);

Vue.component('categories', require('./components/dashboard/CategoriesList.vue').default);
// Vue.component('example-cate', require('./components/ExampleCate.vue').default);
// Vue.component('player-index', require('./components/ExampleCate.vue').default);

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('list-users', require('./components/UsersList.vue').default);
Vue.component('li-contact', require('./components/LiContact.vue').default);
Vue.component('l-subscriptions', require('./components/LSubscriptions.vue').default);
Vue.component('lisposts', require('./components/Posts.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

<template>
    <div class="alert alert-primary spacing_error" role="alert" v-show="show">
        {{ body }}
    </div>
</template>

<script>
export default {
    props: ['message'],
    data() {
        return {
            show : false,
            body : '',
        }
    },
    created() {
        if(this.message) {
            this.errorflash(this.message)
        }
        window.events.$on('errorflash',(message) => this.errorflash(message))
    },
    methods: {
        errorflash(message) {
            this.show = true
            this.body = message

            setTimeout(() => {
                this.hide()
            },8000)
        },
        hide() {
            this.show = false
        }
    }
}
</script>

<style>
.spacing_error {
    position: fixed;
    left: 25px;
    bottom: 80px;
    /*background-color: #ee7f7f;*/
    /*color: black;*/
    z-index: 9999999999999;
    font-weight: bold;
}
</style>

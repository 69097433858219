<template>
    <div>


<section class="my-5">
    <div class="container py-5">
        <div class="row">


            <div class="col-lg-3   col-lg-3   col-md-4 col-sm-12  ">
                <div class="naaves py-5 px-3 my-3">
                    <div class="uk-margin">
                        <div class="uk-search uk-search-default ">
                            <span uk-search-icon></span>
                            <input  @keyup="searchUnit" v-model="search"  class="uk-search-input" type="search" placeholder="ابحث عن شخص">
                        </div>
                    </div>



                    <div class="d-flex align-items-start">
                        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">


 

                        </div>

                      </div>
                </div>
            </div>


            <div class="col-lg-8   col-lg-8   col-md-8 col-sm-12 ">
                <div class="postes" v-if="user_check">
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label"> اكتب مقاله الان</label>
                        <input type="text" class="form-control" v-model="form.titel" id="exampleFormControlInput1" placeholder="#اضف العنوان الرئيسي">
          <span v-if="allerros.titel" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.titel[0] }}</span>

                        <textarea class="form-control" v-model="form.description" id="exampleFormControlTextarea1" rows="3" placeholder="بما تفكر الان"></textarea>
                            <span v-if="allerros.description" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.description[0] }}</span>

                   </div>

                    </div>
                    <div class="uk-margin uk-text-left" v-if="user_check">

                    <button @click.prevent="onSubmit"   :disabled="isDisabled"  class="btn btn-primary p-4 py-3">   ارسال الان <i class="fa-solid fa-arrow-left"></i></button>
                            </div>

   <div class="uk-margin uk-text-left" v-if="!user_check">

                    <a class="btn btn-primary p-4 py-3" href="/login">     تسجيل الدخول  <i class="fa-solid fa-arrow-left"></i></a>
                            </div>






                    <div class="postclinet my-5 py-5">

                       <div class="row">

                        <div  v-for="(user,index) in  blogss_infos.data"  class="col-lg-12   col-lg-12   col-md-12 col-sm-12 d-flex justify-content-center" style="overflow-wrap: anywhere;
">

                            <div class="profile-card22 " >


                                <p >
                                  <a  style="padding:5px;color:red"  v-if="user.user_id ==  user_id" href="javascript:;"    @click="delete_admin(user.id,index)"  class="justify-content-right">
                                       <i  class="fa fa-trash"></i>                                        </a>
                                        <a  style="padding:5px;color:blue"  v-if="user.user_id ==  user_id" :href="'/post/'+user.id+'/edit/'+user.slug"      class="justify-content-right">
                                       <i  class="fa fa-edit"></i>                                        </a>
                                </p>
                                <div class="text-data22 pt-5" v-if="user.user">
                                    <span class="name22" v-if="user.user.name"> {{user.user.name}}</span>
                                    <span class="job22" v-if="user.user.carreer"> {{ user.user.carreer}}</span>
                                  </div>

                                <span class="name22" v-if="!user.user">  محذوف  </span>



                                <div class="media-buttons22" v-if="user.user">
                                  <a v-if="user.user.link1 != 0" target="__blank" :href="user.user.link1" style="background: #4267b2" class="link">
                                    <i class="fa-brands fa-facebook-f"></i>
                                  </a>
                                  <a  v-if="user.user.link3 != 0" target="__blank" :href="user.user.link3" style="background: #1da1f2" class="link">
                                    <i class="fa-brands fa-twitter"></i>
                                  </a>
                                  <a  v-if="user.user.link2 != 0" target="__blank" :href="user.user.link2" style="background: #e1306c" class="link">
                                    <i class="fa-brands fa-instagram"></i>
                                  </a>
                                  <a   v-if="user.user.link4 != 0" target="__blank" :href="user.user.link4"  style="background: #ff0000" class="link">
                                    <i class="fa-brands fa-youtube"></i>
                                  </a>
                                     <a   v-if="user.user.link5 != 0" target="__blank" :href="user.user.link5"  style="background: #1da1f2" class="link">

                                    <i class="fa-brands fa-telegram"></i>
                                  </a>
                                     <a   v-if="user.user.link6 != 0" target="__blank" :href="'https://wa.me/'+user.user.link6" style="background: green;" class="link">

                                    <i class="fa-brands fa-whatsapp"></i>
                                  </a>
                                </div>

                                <div class="pragv">
                                    <h6 class="assm" v-text="user.titel
"></h6>
                                    <p v-html="user.description"></p>
                                </div>


                              </div>
                        </div>





                       </div>


                    </div>





                 <div class="text-center" v-if="blogss_infos.data.length == 0">   لايوجد بيانات للتصفح   </div>





                </div>
            </div>
 <div class="container justify-content-center d-flex py-5">
       <div class="row ">
        <div class="col-md-12">
            <nav aria-label="Page navigation example">
                <ul class="pagination ">
                  <li class="page-item">
                  <pagination align="center" :data="blogss_infos" @pagination-change-page="list"></pagination>

                  </li>
                </ul>
              </nav>
        </div>
       </div>
    </div>



        </div>
 </section>


    </div>
</template>


<script>
    import pagination from 'laravel-vue-pagination'
    export default {
        name:"lisposts",
        components:{
            pagination
        },
        props:['categories'],
         data(){
            return {
           categories_list: this.categories,
           image_info:window.Laravel.path_image,
          categories_count: this.categories.length,
            search: null,
                 blogss_infos:{
                    type:Object,
                    default:null
                },

               isDisabled:false,
                 form: {
                    titel : '',
                    description : '',
                    category_id : window.User?window.User.category_id:0,
                    user_id : window.User?window.User.id:0,

                    },
                    user_check :   window.Laravel.check,
                    user_id :   window.User?window.User.id:0,
                  success_info:'',
                  allerros: [],
                  success : false,

                  url_store:location.origin+'/api/add-blog',

               }
        },
        mounted(){
            this.list()
        },
        methods:{
           onSubmit() {
             var  dataform = new FormData();
                dataform.append('titel', this.form.titel);
                dataform.append('description', this.form.description);
                dataform.append('category_id', window.User.category_id);
                dataform.append('user_id', window.User.id);

                axios.post(this.url_store, dataform)
                .then(({data})=>{
                this.blogss_infos = data;
               this.success = true;
                  this.isDisabled =true;
                 this.allerros = [];
                 this.form.titel = '';
                this.form.description = '';
                flash(' تم اضافه منشور بنجاح  ');
                } ).catch((error) => {
                this.allerros = error.response.data.errors;
                this.success = false;
                });
            },

             category(cat_id){

             axios.get(location.origin+'/api/all/blogs-info?cat='+cat_id)
             .then(({data})=>{
               this.blogss_infos = data;
             })
             },
              list(page=1){
                  axios.get(location.origin+`/api/all/blogs-info?page=${page}`)
                  .then(({data})=>{
                    this.blogss_infos = data;
                 }).catch(({ response })=>{
                 })
            },
            delete_admin(id,index){
             if(confirm("   هل تريد حذف  ؟  ")){

             axios.post(location.origin+'/api/del/blogs-info/'+id)
             .then(({data})=>{
               flash(' تم الحذف بنجاح ');
               this.blogss_infos = data;

             })
            }

             },
            searchUnit(){
               axios.get(location.origin+'/api/all/blogs-info?search='+this.search)
               .then(({data})=>{
                 this.blogss_infos = data;
              })
             },
         },

        computed: {
 getDocumentSize() {
  return this.blogss_infos ? this.blogss_infos.length : 0;
 },
 getDocument() {
  return this.categories_list ? this.categories_list.length : 0;
 }
}
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }

</style>

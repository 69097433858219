<template>
    <div>

       <form class="formtext" action="">
                <div class="uk-margin ">
                  <label for="">اشترك في النشره البريديه</label>
              </div>
                <div class=" ">

                         <input class="uk-input" type="text" v-model="form.email" placeholder="البريد الالكتروني">


              </div>
          <span v-if="allerros.email" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.email[0] }}</span>

                <div class="uk-margin uk-text-left">
                        <button @click.prevent="onSubmit"   :disabled="isDisabled"  class="btn btn-primary p-4 py-3">  ارسال الان <i class="fa-solid fa-arrow-left"></i></button>
              </div>
              </form>

   

    </div>
</template>


<script>
    export default {
        name:"SubsPs",

        data() {
                 return {
                 isDisabled:false,
                 form: {
                     email : '',
                   },
                  success_info:'',
                  allerros: [],
                  success : false,

                  url_store:location.origin+'/api/subscriptions-dp',
                 }
             },
             methods: {

             onSubmit() {
             var  dataform = new FormData();
                 dataform.append('email', this.form.email);
 
                axios.post(this.url_store, dataform).then( response => {
                flash(response.data.message_info);
                this.success_info = response.data.message_info;
                 this.isDisabled =true;
                 this.allerros = [];
                 this.form.email = '';
                 this.success = true;
                } ).catch((error) => {
                this.allerros = error.response.data.errors;
                this.success = false;
                });
            }
             },

    }
</script>

<template>
    <div>
    <form>
                       <fieldset class="uk-fieldset">


                    <div class="uk-margin">
                        <input class="uk-input" v-model="form.name" type="text" placeholder="الاسم بالكامل">

                    </div>
                                  
          <span v-if="allerros.name" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.name[0] }}</span>

                    <div class="uk-margin d-flex">
                        <input class="uk-input uk-width-1-2" type="text" v-model="form.email" placeholder="البريد الالكتروني">
                                                      


    <input class="uk-input uk-width-1-2" type="text" v-model="form.phone" placeholder="رقم الهاتف">

                                  


                    </div>
          <span v-if="allerros.email" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.email[0] }}</span>

          <span v-if="allerros.phone" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.phone[0] }}</span>





                    <div class="uk-margin">
                        <textarea class="uk-textarea" v-model="form.message" rows="5" placeholder="اكتب ملاحظه (اختياري)"></textarea>
                                                  


    </div>
          <span v-if="allerros.message" :class="['label label-danger']" style="color:red;font-weight:bold">{{ allerros.message[0] }}</span>

                    <div class="uk-margin uk-text-left">
                        <button @click.prevent="onSubmit"   :disabled="isDisabled"  class="btn btn-primary p-4 py-3">  ارسال الان <i class="fa-solid fa-arrow-left"></i></button>
                    </div>


 

                    </fieldset>

      </form>
   

    </div>
</template>


<script>
    export default {
        name:"contactPs",

        data() {
                 return {
                 isDisabled:false,
                 form: {
                    name : '',
                    email : '',
                    phone : '',
                    message : '',
                  },
                  success_info:'',
                  allerros: [],
                  success : false,

                  url_store:location.origin+'/api/contact/us',
                 }
             },
             methods: {

             onSubmit() {
             var  dataform = new FormData();
                dataform.append('name', this.form.name);
                dataform.append('email', this.form.email);
                dataform.append('message', this.form.message);
                dataform.append('phone', this.form.phone);

                axios.post(this.url_store, dataform).then( response => {
                flash(response.data.message_info);
                this.success_info = response.data.message_info;
                 this.isDisabled =true;
                 this.allerros = [];
                 this.form.name = '';
                this.form.email = '';
                this.form.message = '';
                this.form.phone = '';
                this.success = true;
                } ).catch((error) => {
                this.allerros = error.response.data.errors;
                this.success = false;
                });
            }
             },

    }
</script>

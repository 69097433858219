<template>
  <div class="row">
  <div v-if="show_status" class="alert alert-primary alert-dismissible text-white " role="alert" style="    position: absolute;
    z-index: 999999;
    left: 23px;
    bottom: 4px;
    " >
    <h2 class="text-sm" v-text="' البريد الالكتروني  : '+this.show_user.email"></h2>
    <span class="text-sm" v-text="'   الحاله :'"></span>

    <span class="badge badge-sm bg-gradient-success" v-if="user.status == 1">مفعل</span>
      <span class="badge badge-sm bg-gradient-danger" v-if="user.status == 0"> غير مفعل </span>

                    <button @click="show_cancel"  type="button" class="btn-close text-lg py-3 opacity-10">
                      <span  >×</span>
                    </button>

               </div>
   <div class="col-md-6">
  <div class="input-group input-group-outline m-4">
                    <label class="form-label">بحث</label>
                <input type="text"   @keyup="searchUnit" v-model="search" class="form-control">
    </div>

    </div>
    <div class="col-md-3">
    <a @click="clearS" class="m-4 nav-link mb-0 px-0 py-1  " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                <i class="material-icons text-lg position-relative">autorenew</i>
                <span class="ms-1">اعاده تحميل</span>
              </a>
    </div>
    <div class="col-md-3">
    <a  href="/dashboard/subscriptions/create"   class="m-4 nav-link mb-0 px-0 py-1  " >
                <i class="material-icons text-lg position-relative">add</i>
                <span class="ms-1"> أضف </span>
              </a>
    </div>
  <table class="table align-items-center mb-0" v-if="subscriptions && subscriptions.data.length > 0">
    <thead>
      <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">   تسلسل </th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">  البريد الالكتروني  </th>
         <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الحاله</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">التاريخ</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الاعدادات</th>
       </tr>
    </thead>
    <tbody>
  <tr   v-for="(user,index) in subscriptions.data" :key="index">
  <td>
  <div class="d-flex px-2 py-1">
    <div>
          {{ index + 1 }}#
          </div>
    </div>
    </td>

    <td>
      <div class="d-flex px-2 py-1">

        <div class="d-flex flex-column m-2">
          <h6 class="mb-0 text-sm" v-text="user.email"></h6>
         </div>
      </div>
    </td>

    <td class="align-middle text-center text-sm">
    <span class="badge badge-sm bg-gradient-success" v-if="user.status == 1">مفعل</span>
      <span class="badge badge-sm bg-gradient-danger" v-if="user.status == 0"> غير مفعل </span>

    </td>
    <td class="align-middle text-center">
      <span class="text-secondary text-xs " >{{  user.created_at | moment("dddd, MMMM Do YYYY") }}</span>
    </td>
    <td class="align-middle">
      <div class="ms-auto text-end">
 <a class="btn btn-link text-danger text-gradient px-1 mb-0" href="javascript:;"    @click="delete_admin(user.id,index)"><i class="material-icons">delete</i></a>
 <a class="btn btn-link text-dark px-1 mb-0" :href="'/dashboard/subscriptions/edit/'+user.id"><i class="material-icons">edit</i></a>
 <a class="btn btn-link text-dark px-1 mb-0"  href="javascript:;"    @click="show_admin(user)"><i class="material-icons"> remove_red_eye</i></a>
 <a class="btn btn-link text-dark px-1 mb-0"  href="javascript:;"    @click="changestatus_admin(user.id,index)"> الحاله ؟</a>

</div>
    </td>
  </tr>
  <pagination :data="subscriptions" @pagination-change-page="list" />

                     </tbody>
                   </table>
                   <table v-else>
                     <div class="text-secondary">
                      <h3 class="text-center text-uppercase text-secondary  font-weight-bolder opacity-7">
لايوجد   اشتراكات </h3></div>
                    </table>
  </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination';

     export default {
        name:"subscriptions",
        components:{
        'pagination': LaravelVuePagination,

        },
        data(){
            return {
            show_status:false,
            show_user:{
            id:null,
             email:'',
             status:'',
            },
            search: null,
            subscriptions:{
                    type:Object,
                    default:null
              }
            }
        },
        mounted(){
            this.list();
        },
        methods:{
             list(page=1){
                 axios.get(`${location.origin}/api/subscriptions/list?page=${page}`)
                 .then(({data})=>{
                    this.subscriptions = data
                }).catch(({ response })=>{
                    console.error(response);
                })
            },
            searchUnit(){
               axios.get(location.origin+'/api/subscriptions/list?search='+this.search)
               .then(({data})=>{
                  this.subscriptions = data;
              })
             },
             clearS(){
             this.list();
             this.search = null;

             },
             delete_admin(id,index){
             if(confirm("   هل تريد حذف  ؟  ")){

             axios.post(location.origin+'/api/subscriptions/'+id)
             .then(({data})=>{
               flash(' تم الحذف بنجاح ');
               this.subscriptions = data;

             })
            }

             },
             show_admin(user){
              this.show_status = true;
              this.show_user.email = user.email;
              this.show_user.status = user.status;
               this.show_user.id = user.id;

             },
             show_cancel(){
              this.show_status = false;

             },
             changestatus_admin(id,index){
             if(confirm("        هل تريد تغير الحاله ؟  ")){

             axios.put(location.origin+'/api/subscriptions/change-status/'+id)
             .then(({data})=>{
               flash('  تم تغير الحاله بنجاح   ');
               this.subscriptions = data;

             })
            }

             },

        }
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }
</style>

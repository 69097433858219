<template>
   <div class="container py-5">

        <div class="uk-margin">
            <div class="uk-search uk-search-default">
                <span uk-search-icon></span>
                <input @keyup="searchUnit" v-model="search" class="uk-search-input" type="search" placeholder="ابحث عن شخص">


            </div>
        </div>

    <div class="text-centernavs justify-content-center">
            <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                <li @click.prevent="category(0)" class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"> الكل</button>
                </li>
                <li v-for="(cat,index) in  categories_list" class="nav-item" role="presentation">
                  <button @click.prevent="category(cat.id)"  class="nav-link" id="pills-tabContent" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-profile"> {{ cat.titel }}</button>
                </li>

              </ul>
                  <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">


 <div class="row">




  <div class="col-lg-4   col-lg-4   col-md-4 col-sm-4 d-flex justify-content-center" v-for="(user,index) in  users_infos.data">
        <div class="profile-card">
            <div class="image">
              <img :src="image_info+'/'+user.image" :alt="user.name" class="profile-img" />
            </div>

            <div class="text-data">
              <span class="name" v-text="user.name"></span>
              <span class="job" v-text="user.carreer"></span>
            </div>
             <div class="media-buttons">

              <a v-if="user.link1" :href="user.link1" target="_blank" style="background: #4267b2" class="link">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a v-if="user.link2" :href="user.link2" style="background: #e1306c" class="link">
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a v-if="user.link3" :href="user.link3" style="background: #1da1f2" class="link">
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a v-if="user.link4" :href="user.link4" style="background: #ff0000" class="link">
                <i class="fa-brands fa-youtube"></i>
              </a>
               <a v-if="user.link5" :href="user.link5" style="background: #4267b2" class="link">
             <i class="fa-brands fa-telegram"></i>
              </a>
              <a v-if="user.link6" :href="'https://wa.me/'+user.link6" style="background: green" class="link">
<i class="fa-brands fa-whatsapp"></i>
              </a>

 <a v-if="user.link11" :href="user.link11" style="background: #0077b5" class="link">
    <i class="fa-brands fa-linkedin"></i>
</a>

<a v-if="user.link12" :href="user.link12" style="background: #000" class="link">
    <i class="fa-solid fa-globe"></i>
</a>

<a v-if="user.link10" :href="user.link10" style="background: #18ffff" class="link">
    <i class="fa-brands fa-tiktok"></i>
</a>



            </div>



          </div>
    </div>




</div>
  <div class="container justify-content-center d-flex py-5">
       <div class="row ">
        <div class="col-md-12">
            <nav aria-label="Page navigation example">
                <ul class="pagination ">
                  <li class="page-item">
                  <pagination align="center" :data="users_infos" @pagination-change-page="list"></pagination>

                  </li>
                </ul>
              </nav>
        </div>
       </div>
    </div>



    </div>
    </div>
    </div>

    </div>
</template>

<script>
    import pagination from 'laravel-vue-pagination'
    export default {
        name:"list-users",
        components:{
            pagination
        },
        props:['categories'],
         data(){
            return {
           categories_list: this.categories,
           image_info:window.Laravel.path_image,
          categories_count: this.categories.length,
            search: null,
                 users_infos:{
                    type:Object,
                    default:null
                },
               }
        },
        mounted(){
            this.list()
        },
        methods:{

             category(cat_id){

             axios.get(location.origin+'/api/all/user-info?cat='+cat_id)
             .then(({data})=>{
               this.users_infos = data;
               console.log(this.users_infos);
            })
             },
              list(page=1){
                  axios.get(location.origin+`/api/all/user-info?page=${page}`)
                  .then(({data})=>{
                    this.users_infos = data;
                 }).catch(({ response })=>{
                 })
            },
            searchUnit(){
               axios.get(location.origin+'/api/all/user-info?search='+this.search)
               .then(({data})=>{
                 this.users_infos = data;
              })
             },
         },

        computed: {
 getDocumentSize() {
  return this.users_infos ? this.users_infos.length : 0;
 },
 getDocument() {
  return this.categories_list ? this.categories_list.length : 0;
 }
}
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }

</style>

<template>
  <div class="row">
 
   <div class="col-md-6">
  <div class="input-group input-group-outline m-4">
                    <label class="form-label">بحث</label>
                <input type="text"   @keyup="searchUnit" v-model="search" class="form-control">
    </div>

    </div>
    <div class="col-md-3">
    <a @click="clearS" class="m-4 nav-link mb-0 px-0 py-1  " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                <i class="material-icons text-lg position-relative">autorenew</i>
                <span class="ms-1">اعاده تحميل</span>
              </a>
    </div>
    <div class="col-md-3">
 
    </div>
  <table class="table align-items-center mb-0" v-if="visitors && visitors.data.length > 0">
    <thead>
      <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">   تسلسل </th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> browser  </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">  device   </th>
         <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">التاريخ</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الاعدادات</th>
       </tr>
    </thead>
    <tbody>
  <tr   v-for="(user,index) in visitors.data" :key="index"   :style="user.read == 0 ? 'background-color: #EC407A; color:#ffff':''">
  <td>
  <div class="d-flex px-2 py-1">
    <div>
          {{ index + 1 }}#

          </div>
    </div>
    </td>

    <td>
      <div class="d-flex px-2 py-1">

        <div class="d-flex flex-column m-2">
          <h6 class="mb-0 text-sm" v-text="user.browser"></h6>
         </div>
      </div>
    </td>
    <td>
      <p class="text-xs font-weight-bold mb-0"  v-html="user.device"></p>
      <p class="text-xs text-secondary mb-0"  v-html="user.ip"></p>
    </td>
    
    <td class="align-middle text-center">
      <span class="text-secondary text-xs " >{{  user.created_at | moment("dddd, MMMM Do YYYY") }}</span>
    </td>
    <td class="align-middle">
      <div class="ms-auto text-end">
 <a class="btn btn-link text-dark text-gradient px-1 mb-0" href="javascript:;"    @click="delete_admin(user.id,index)"><i class="material-icons">delete</i></a>
  <a class="btn btn-link text-dark px-1 mb-0" :href="'/dashboard/visitors/'+user.id"><i class="material-icons"> remove_red_eye</i></a>
  

</div>
    </td>
  </tr>
  <pagination :data="visitors" @pagination-change-page="list" />

                     </tbody>
                   </table>
                   <table v-else>
                     <div class="text-secondary">
                      <h3 class="text-center text-uppercase text-secondary  font-weight-bolder opacity-7">
لايوجد    </h3></div>
                    </table>
  </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination';

     export default {
        name:"visitors",
        components:{
        'pagination': LaravelVuePagination,

        },
        data(){
            return {
            show_status:false,
            show_user:{
            id:null,
             name:'',
             email:''
                         },
            search: null,
            visitors:{
                    type:Object,
                    default:null
              }
            }
        },
        mounted(){
            this.list();
        },
        methods:{
             list(page=1){
                 axios.get(`${location.origin}/api/visitors/list?page=${page}`)
                 .then(({data})=>{
                    this.visitors = data
                }).catch(({ response })=>{
                    console.error(response);
                })
            },
            searchUnit(){
               axios.get(location.origin+'/api/visitors/list?search='+this.search)
               .then(({data})=>{
                  this.visitors = data;
              })
             },
             clearS(){
             this.list();
             this.search = null;

             },
             delete_admin(id,index){
             if(confirm("   هل تريد حذف  ؟  ")){

             axios.post(location.origin+'/api/visitors/'+id)
             .then(({data})=>{
               flash(' تم الحذف بنجاح ');
               this.visitors = data;

             })
            }

             },
             show_admin(user){
              this.show_status = true;
              this.show_user.browser = user.browser;
              this.show_user.device = user.device;
              this.show_user.id = user.id;

             },
             show_cancel(){
              this.show_status = false;

             },
             changestatus_admin(id,index){
             if(confirm("        هل تريد تغير الحاله ؟  ")){

             axios.put(location.origin+'/api/visitors/change-status/'+id)
             .then(({data})=>{
               flash('  تم تغير الحاله بنجاح   ');
               this.visitors = data;

             })
            }

             },

        }
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }
</style>

<template>
  <div class="row">
   <div class="col-md-6">
  <div class="input-group input-group-outline m-4">
                    <label class="form-label">بحث</label>
                <input type="text"   @keyup="searchUnit" v-model="search" class="form-control">
    </div>

    </div>
    <div class="col-md-3">
    <a @click="clearS" class="m-4 nav-link mb-0 px-0 py-1  " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                <i class="material-icons text-lg position-relative">autorenew</i>
                <span class="ms-1">اعاده تحميل</span>
              </a>
    </div>
    <div class="col-md-3">
    <a  href="/dashboard/users/create"   class="m-4 nav-link mb-0 px-0 py-1  " >
                <i class="material-icons text-lg position-relative">add</i>
                <span class="ms-1"> أضف </span>
              </a>
    </div>
  <table class="table align-items-center mb-0" v-if="users && users.data.length > 0">
    <thead>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الاسم</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">البريد الالكتروني</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الحاله</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">التاريخ</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الاعدادات</th>
       </tr>
    </thead>
    <tbody>
  <tr   v-for="(user,index) in users.data" :key="index">
    <td>
      <div class="d-flex px-2 py-1">
        <div>
          <img :src="user.is_image" class="avatar avatar-sm me-3 border-radius-lg" alt="user1">
        </div>
        <div class="d-flex flex-column m-2">
          <h6 class="mb-0 text-sm" v-text="user.name"></h6>
          <p class="text-xs text-secondary mb-0" v-text="user.username"></p>
        </div>
      </div>
    </td>
    <td>
      <p class="text-xs font-weight-bold mb-0"  v-text="user.email"></p>
      <p class="text-xs text-secondary mb-0">--</p>
    </td>
    <td class="align-middle text-center text-sm">
    <span class="badge badge-sm bg-gradient-success" v-if="user.status == 1">مفعل</span>
      <span class="badge badge-sm bg-gradient-danger" v-if="user.status == 0"> غير مفعل </span>

    </td>
    <td class="align-middle text-center">
      <span class="text-secondary text-xs " >{{  user.created_at | moment("dddd, MMMM Do YYYY") }}</span>
    </td>
    <td class="align-middle">
      <div class="ms-auto text-end">
 <a class="btn btn-link text-danger text-gradient px-1 mb-0" href="javascript:;"    @click="delete_user(user.id,index)"><i class="material-icons">delete</i></a>
 <a class="btn btn-link text-dark px-1 mb-0" :href="'/dashboard/users/edit/'+user.id"><i class="material-icons">edit</i></a>
 <a class="btn btn-link text-dark px-1 mb-0" :href="'/dashboard/users/'+user.id" ><i class="material-icons"> remove_red_eye</i></a>
 <a class="btn btn-link text-dark px-1 mb-0"  href="javascript:;"    @click="changestatus_user(user.id,index)"> الحاله ؟</a>

</div>
    </td>
  </tr>
  <pagination :data="users" @pagination-change-page="list" />

                     </tbody>
                   </table>
                   <table v-else>
                     <div class="text-secondary">                      <h3 class="text-center text-uppercase text-secondary  font-weight-bolder opacity-7">

                       لايوجد أعضاء                       </h3></div>
                    </table>
  </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination';

     export default {
        name:"users",
        components:{
        'pagination': LaravelVuePagination
        },
        data(){
            return {
            search: null,
            users:{
                    type:Object,
                    default:null
              }
            }
        },
        mounted(){
            this.list();
        },
        methods:{
             list(page=1){
                 axios.get(location.origin+`/api/user/list?page=${page}`)
                 .then(({data})=>{
                    this.users = data
                }).catch(({ response })=>{
                    console.error(response);
                })
            },
            searchUnit(){
               axios.get(location.origin+'/api/user/list?search='+this.search)
               .then(({data})=>{
                  this.users = data;
              })
             },
             clearS(){
             this.list();
             this.search = null;

             },
             delete_user(id,index){
             if(confirm("   هل تريد حذف  ؟  ")){

             axios.post(location.origin+'/api/user/'+id)
             .then(({data})=>{
               flash(' تم الحذف بنجاح ');
               this.users = data;

             })
            }

             },
             changestatus_user(id,index){
             if(confirm("        هل تريد تغير الحاله ؟  ")){

             axios.put(location.origin+'/api/user/change-status/'+id)
             .then(({data})=>{
               flash('  تم تغير الحاله بنجاح   ');
               this.users = data;

             })
            }

             },

        }
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }
</style>

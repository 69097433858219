<template>
  <div class="row">
  <div v-if="show_status" class="alert alert-primary alert-dismissible text-white " role="alert" style="    position: absolute;
    z-index: 999999;
    left: 23px;
    bottom: 4px;
    " >
    <h2 class="text-sm" v-text="'العنوان  : '+this.show_user.titel"></h2>
    <span class="text-sm" v-text="'  وصف قصير :'+this.show_user.short_description"></span>


                    <button @click="show_cancel"  type="button" class="btn-close text-lg py-3 opacity-10">
                      <span  >×</span>
                    </button>

               </div>
   <div class="col-md-6">
  <div class="input-group input-group-outline m-4">
                    <label class="form-label">بحث</label>
                <input type="text"   @keyup="searchUnit" v-model="search" class="form-control">
    </div>

    </div>
    <div class="col-md-3">
    <a @click="clearS" class="m-4 nav-link mb-0 px-0 py-1  " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                <i class="material-icons text-lg position-relative">autorenew</i>
                <span class="ms-1">اعاده تحميل</span>
              </a>
    </div>
    <div class="col-md-3">
    <a  href="/dashboard/services/create"   class="m-4 nav-link mb-0 px-0 py-1  " >
                <i class="material-icons text-lg position-relative">add</i>
                <span class="ms-1"> أضف </span>
              </a>
    </div>
  <table class="table align-items-center mb-0" v-if="services && services.data.length > 0">
    <thead>
      <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">   تسلسل </th>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> العنوان  </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">   نوع الخدمه </th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الحاله</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">التاريخ</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">الاعدادات</th>
       </tr>
    </thead>
    <tbody>
  <tr   v-for="(user,index) in services.data" :key="index">
  <td>
  <div class="d-flex px-2 py-1">
    <div>
          {{ index + 1 }}#
          </div>
    </div>
    </td>

    <td>
      <div class="d-flex px-2 py-1">

        <div class="d-flex flex-column m-2">
          <h6 class="mb-0 text-sm" v-text="user.titel"></h6>
         </div>
      </div>
    </td>
    <td>
      <p class="text-xs font-weight-bold mb-0"  v-if="user.parent_id == 0">خدمه رئيسيه </p>
      <p class="text-xs text-secondary mb-0" v-else> خدمه فرعيه </p>
    </td>
    <td class="align-middle text-center text-sm">
    <span class="badge badge-sm bg-gradient-success" v-if="user.status == 1">مفعل</span>
      <span class="badge badge-sm bg-gradient-danger" v-if="user.status == 0"> غير مفعل </span>

    </td>
    <td class="align-middle text-center">
      <span class="text-secondary text-xs " >{{  user.created_at | moment("dddd, MMMM Do YYYY") }}</span>
    </td>
    <td class="align-middle">
      <div class="ms-auto text-end">
 <a class="btn btn-link text-danger text-gradient px-1 mb-0" href="javascript:;"    @click="delete_admin(user.id,index)"><i class="material-icons">delete</i></a>
 <a class="btn btn-link text-dark px-1 mb-0" :href="'/dashboard/services/edit/'+user.id"><i class="material-icons">edit</i></a>
 <a class="btn btn-link text-dark px-1 mb-0" :href="'/dashboard/services/'+user.id"><i class="material-icons"> remove_red_eye</i></a>
 <a class="btn btn-link text-dark px-1 mb-0"  href="javascript:;"    @click="changestatus_admin(user.id,index)"> الحاله ؟</a>

</div>
    </td>
  </tr>
  <pagination :data="services" @pagination-change-page="list" />

                     </tbody>
                   </table>
                   <table v-else>
                     <div class="text-secondary">
                      <h3 class="text-center text-uppercase text-secondary  font-weight-bolder opacity-7">
                       لايوجد  أقسام عنا
</h3></div>
                    </table>
  </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination';

     export default {
        name:"Services",
        components:{
        'pagination': LaravelVuePagination,

        },
        data(){
            return {
            show_status:false,
            show_user:{
            id:null,
             titel:'',
             description:'',
             icons:''
            },
            search: null,
            services:{
                    type:Object,
                    default:null
              }
            }
        },
        mounted(){
            this.list();
        },
        methods:{
             list(page=1){
                 axios.get(`${location.origin}/api/services/list?page=${page}`)
                 .then(({data})=>{
                    this.services = data
                }).catch(({ response })=>{
                    console.error(response);
                })
            },
            searchUnit(){
               axios.get(location.origin+'/api/services/list?search='+this.search)
               .then(({data})=>{
                  this.services = data;
              })
             },
             clearS(){
             this.list();
             this.search = null;

             },
             delete_admin(id,index){
             if(confirm("   هل تريد حذف  ؟  ")){

             axios.post(location.origin+'/api/services/'+id)
             .then(({data})=>{
               flash(' تم الحذف بنجاح ');
               this.services = data;

             })
            }

             },
             show_admin(user){
              this.show_status = true;
              this.show_user.titel = user.titel;
              this.show_user.description = user.description;
              this.show_user.short_description = user.short_description;
              this.show_user.id = user.id;

             },
             show_cancel(){
              this.show_status = false;

             },
             changestatus_admin(id,index){
             if(confirm("        هل تريد تغير الحاله ؟  ")){

             axios.put(location.origin+'/api/services/change-status/'+id)
             .then(({data})=>{
               flash('  تم تغير الحاله بنجاح   ');
               this.services = data;

             })
            }

             },

        }
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }
</style>
